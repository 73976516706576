/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

import $ from 'jquery';
import 'jquery';
import 'popper.js'
import 'bootstrap'
import 'perfect-scrollbar'
import 'owl.carousel';
import 'owl.carousel2.thumbs/dist/owl.carousel2.thumbs.min';
import '../js/public/parallax.min.js';
import '../js/public/lightbox-plus-jquery.min.js';
import '../js/public/custom'

import Vue from 'vue/dist/vue.esm.js';

import './infrastructure/Flash';
import './infrastructure/Flashes';
import './infrastructure/Tab';
import './infrastructure/Tabs';
import './infrastructure/Modal';
import './infrastructure/DeleteLink';
import './infrastructure/DeleteModuleItem';
import './infrastructure/select2entity';
import './components/EventCheckout';
import './components/CountriesFilter';
import './components/RangeFilter';
import './components/WishlistWidget';
import './components/CartWidget';
import './components/ReviewModal';
import './components/Cart';
import './components/Autocomplete';
import './components/AddToCart';
import './components/Checkout';
import './components/FilterWidget';
import './components/ActiveFiltersWidget';
import './components/PaypalButtons';
import './components/Card';
import './components/CardForm';
import './components/SumupEmbedded';
import './components/SumupSaveCard';
import './components/Settle';
import './components/MysteryBoxCheckout';
import './components/EventPaymentMethods';
import './components/CheckoutPaymentMethods';
import './components/LandingPageCategorySelector';
// import './components/MapCluster';
import './components/Gmaps';


import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

window.$ = $;


window.EventManager = new class {
    constructor() {
        this.vue = new Vue();
    }

    fire(event, data = null) {
        this.vue.$emit(event, data);
    }

    listen(event, callback) {
        this.vue.$on(event, callback);
    }
};

const app = new Vue({
    el: '#app',
    data: {
        showModal: false,
        dynamicComponents: []
    },
    mounted() {
        EventManager.listen('add-component', this.addComponent);
        EventManager.listen('remove-component', this.removeComponent);
    },
    methods: {
        addComponent(data) {
            this.dynamicComponents.push(data);
        },

        removeComponent(componentId) {
            let index = this.dynamicComponents.findIndex(item => item.id === componentId);

            this.dynamicComponents.splice(index, 1);
        },
    }
});

Vue.directive('init', {
    bind: function (el, binding, vnode) {
        vnode.context[binding.arg] = binding.value;
    }
});

window.flash = function (message, level = 'success') {
    window.EventManager.fire('flash', {message, level});
};
const application = Application.start();
const context = require.context("./controllers", false, /\.js$/);
application.load(definitionsFromContext(context));

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});

let elements = document.querySelectorAll('input, select, textarea');

elements.forEach(element => {
    element.addEventListener('invalid', function () {
        element.scrollIntoView(false);
    });
});


$(function () {
    $("form").submit(function (event) {
        $(this).find('button[type="submit"]').prop("disabled", true);
    });
});

