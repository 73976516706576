import PerfectScrollbar from 'perfect-scrollbar';
import $ from "jquery";

$(document).ready(function () {

    $("#showMorePartners").click(function () {
        // Показваме всички скрити колони
        $(".hidden-column").removeClass("hidden-column");

        // Скриваме бутона след натискане
        $(this).hide();
    });

    function fixHeaderStyle() {
        $(".product-page").addClass('fixed');
        $(".blog_post_view-page").addClass('fixed');
        $(".cart-page").addClass('fixed');
        $(".cart_checkout-page").addClass('fixed');
        $(".info-page").addClass('fixed');
        $(".locations-page").addClass('fixed');
        $(".cart_auth-page").addClass('fixed');
        $("header[class^='security']").addClass('fixed');
        $("header[class^='user_profile']").addClass('fixed');
        $(".app_registration-page").addClass('fixed');
        $(".forgot-password-page").addClass('fixed');
        $(".thank_you_cod-page").addClass('fixed');
        $(".thank_you_bank-page").addClass('fixed');
        $(".thank-you-page-page").addClass('fixed');
        $(".faqs_list-page").addClass('fixed');
        $(".sitemap-page").addClass('fixed');
        $(".checkout_cancel-page").addClass('fixed');
        $(".reset-password-page").addClass('fixed');
        $(".-page").addClass('fixed');
    }

    fixHeaderStyle();
    $("#nav-toggle").click(function (e) {
        $(this).toggleClass('active');
        $('.mega-menu').toggleClass('active');
        $('page').toggleClass('page-hide');
        e.preventDefault();
    });

    $('.none-link').click(function (e) {
        e.preventDefault();
        $(this).next().toggleClass('open');
    });

    // При клик на линк с клас mapMove
    $('.mapMove').on('click', function (e) {
        e.preventDefault(); // Избягваме стандартното поведение на линка

        // Вземаме височината на хедъра
        var headerHeight = $('header').outerHeight();

        // Вземаме целевия елемент (секцията, до която искаме да скролнем)
        var target = $($(this).attr('href'));

        if (target.length) {
            // Изчисляваме позицията на целевата секция минус височината на хедъра
            var scrollToPosition = target.offset().top - headerHeight;

            // Плавен скрол
            $('html, body').animate({
                scrollTop: scrollToPosition
            }, 0); // 800 милисекунди за плавен ефект
        }
    });


    // Initialize Owl Carousel
    var owl = $('.owl-history').owlCarousel({
        loop: false,
        margin: 10,
        nav: false,
        dots: false,
        items: 1,
        mouseDrag: false
    });

    // Change slide when clicking year buttons
    $('.year-btn').on('click', function () {
        // Remove 'active' class from all buttons and add it to the clicked one
        $('.year-btn').removeClass('active');
        $(this).addClass('active');

        // Get the year index (data-year attribute) and go to that slide
        var yearIndex = $(this).data('year');
        owl.trigger('to.owl.carousel', [yearIndex, 300]); // Change slide with animation speed 300ms
    });

    // Sync year button with carousel slide change
    owl.on('changed.owl.carousel', function (event) {
        var currentIndex = event.item.index; // Get the current slide index
        $('.year-btn').removeClass('active');
        $('.year-btn[data-year="' + currentIndex + '"]').addClass('active');
    });

    $('.owl-projects').owlCarousel({
        loop: true,
        margin: 30,
        dots: false,
        responsiveClass: true,
        center: true,
        navText: ["<img src='/build/images/icons/carousel-arrow.svg'>", "<img src='/build/images/icons/carousel-arrow.svg'>"],
        stagePadding: 100,
        items: 3,
        nav: true,
        responsive: {
            0: {
                items: 1,
                stagePadding: 60,
            },
            768: {
                items: 2,
                stagePadding: 30,
            },
            1500: {}
        }
    })

    $('.owl-events').owlCarousel({
        loop: true,
        margin: 30,
        dots: false,
        responsiveClass: true,
        // center: true,
        navText: ["<img src='/build/images/icons/carousel-arrow.svg'>", "<img src='/build/images/icons/carousel-arrow.svg'>"],
        stagePadding: 10,
        items: 4,
        nav: true,
        responsive: {
            0: {
                items: 1,
                stagePadding: 60,
            },
            768: {
                items: 2,
                stagePadding: 30,
            },
            1500: {}
        }
    })

    $('.carousel').carousel({
        interval: 2500,
        touch: true
    });

    $(window).scroll(function () {
        var sticky = $('header'),
            scroll = $(window).scrollTop();

        if (scroll >= 100)
            sticky.addClass('fixed');
        else
            sticky.removeClass('fixed');

        fixHeaderStyle();
    });

    $('.intro-wine-tests .owl-carousel').owlCarousel({
        loop: true,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    })

//    $('#activate-gift-card').click(function () {
//        $(".gift-card-action").toggle(this.checked);
//    });
//
//    $('#activate-for-gift').click(function () {
//        $(".mystery-box-order, .checkout").toggleClass('for-gift', this.checked);
//    });

    $('.countries h3').click(function (e) {
        $('.countries .countries-result').slideToggle(function () {
            $('.countries').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sort h3').click(function (e) {
        $('.sort .sort-result').slideToggle(function () {
            $('.sort').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sidebar h3, .toggle-filter').click(function (e) {
        $('.sidebar .widgets').slideToggle(function () {
            $('.sidebar').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.widget h4').click(function (e) {
        $(this).siblings('.widget-box').slideToggle(function () {
            $(this).closest('.widget').toggleClass('widget-active');
        });
        e.preventDefault();
    });

    $('.aside-dropdown .dd-menu > a').click(function (e) {
        $(this).siblings('ul').slideToggle(function () {
            $(this).parent().toggleClass('dd-menu-active');
        });
        e.preventDefault();
    });

    $('.search-widget').click(function () {
        $('.search').toggleClass('open');
    });

    $('.nav-btn').click(function () {
        $(this).toggleClass('open');
        $('nav').toggleClass('open');
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.dropdown-btn').click(function () {
            $(this).siblings('ul, .mega-menu').slideToggle();
            $(this).find('.fa').toggleClass('fa-angle-down fa-angle-up');
        });
    } else {
        $('.menu > li').hover(function () {
            $(this).find('.mega-menu, .sub-menu').first().stop().slideDown(200);
        }, function () {
            $(this).find('.mega-menu, .sub-menu').stop().slideUp(200);
        });
    }

    if ($('.owl-testimonials').length) {

        $('.owl-testimonials').owlCarousel({
            loop: true,
            margin: 0,
            responsiveClass: true,
            dots: true,
            navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                600: {
                    items: 1,
                    nav: false
                },
                1000: {
                    items: 1,
                    nav: false
                },
                1600: {
                    items: 1,
                    nav: false
                }
            }
        });
    }

    let productsCarousel = $('.owl-products').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: false,
        stagePadding: 150,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: true,
                stagePadding: 80
            },
            600: {
                items: 2,
                nav: true,
                stagePadding: 80
            },
            770: {
                items: 3,
                nav: true,
                stagePadding: 80
            },
            1200: {
                items: 4,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 6,
                nav: true
            }
        }
    });

    $('.mystery-box-comments.owl-carousel').owlCarousel({
        loop: true,
        margin: 25,
        nav: true,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                stagePadding: 25,
                margin: 10
            },
            575: {
                items: 1,
                stagePadding: 150
            },
            768: {
                items: 2,
                stagePadding: 40
            },
            1024: {
                items: 2,
                stagePadding: 120
            },
            1200: {
                items: 3
            }
        }
    })

    const throttleFunction = (func, delay) => {

        // Previously called time of the function
        let prev = 0;
        return (...args) => {
            // Current called time of the function
            let now = new Date().getTime();

            // Logging the difference between previously
            // called and current called timings

            // If difference is greater than delay call
            // the function again.
            if (now - prev > delay) {
                prev = now;

                // "..." is the spread operator here
                // returning the function with the
                // array of arguments
                return func(...args);
            }
        }
    }

    function triggerOnScroll(owl, e) {
        let deltaX = e.originalEvent.deltaX;
        let deltaY = e.originalEvent.deltaY;

        if (deltaY <= -1 || deltaY >= 1) {
            return;
        }
        if (deltaX > 0) {
            productsCarousel.trigger('next.owl');
        } else {
            productsCarousel.trigger('prev.owl');
        }
        e.preventDefault();
    }

    productsCarousel.on('wheel', '.owl-stage', throttleFunction((e) => {
        triggerOnScroll(productsCarousel, e);
    }, 1000));

    $('.owl-products-no-clone').owlCarousel({
        loop: false,
        rewind: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        stagePadding: 150,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 80
            },
            600: {
                items: 2,
                nav: false,
                stagePadding: 80
            },
            770: {
                items: 3,
                nav: true,
                stagePadding: 80
            },
            1200: {
                items: 4,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 4,
                nav: true
            }
        }
    });


    $('.slider-module .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    })


    $('.product-slide-module .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                stagePadding: 30,
                nav: false,
                items: 1
            },
            426: {
                stagePadding: 30,
                nav: false,
                items: 2
            },
            660: {
                stagePadding: 40,
                nav: false,
                items: 3
            },
            767: {
                items: 3
            },
            796: {
                items: 4
            },
            1150: {
                items: 5
            },
            1316: {
                items: 6
            }
        }
    })


    $('.slide-offer .owl-carousel').owlCarousel({

        loop: false,
        margin: 10,
        nav: false,
        responsive: {
            0: {
                stagePadding: 50,
                items: 1
            },
            450: {
                stagePadding: 20,
                items: 2
            },
            580: {
                stagePadding: 30,
                items: 3
            }
        }
    })

//questionnaire radio
    $('.christmas-landing.questionnaire form .form-group label.radio-label').click(function () {
        $(".christmas-landing.questionnaire form .form-group label.radio-label").removeClass("active")
        $(this).addClass("active")
    })

    //questionnaire checkbox

    $(".questionnaire form .form-group .checkbox-label").click(function () {
        $('[name="checkbox1"]').change(function () {
            if ($(this).is(':checked')) {
                $(this).parent().addClass("active")
            } else {
                $(this).parent().removeClass("active")
            }
        });
    });


    $(".intro-bg .download-btn").click(function () {
        const textElement = document.getElementById("form-offset")
        textElement.scrollIntoView()
    })


    $(".scroll-reviews").click(function (e) {
        scrollPos = $('.comments').offset().top - 50;
        scrollTo(scrollPos, 2000);

        e.preventDefault();
    });

    const container = document.querySelector('.filter-list, .cart-result');
    if (container) {
        const ps = new PerfectScrollbar(container);

        $(".cart-result").hover(function () {
            ps.update();
        });

    }

    $('.read-more').click(function () {
        $(this).parent().find('.product-desc').addClass('full');
        $(this).next('.read-less').show();
    })
    $('.read-less').click(function () {
        $(this).parent().find('.product-desc').removeClass('full');
        $(this).hide();
        if (window.innerWidth > 767) {
            window.scrollTo({top: 0, left: 0, behavior: "instant"})
        }
    })

    var currentUrl = window.location.pathname;
    $('.textpage-nav ul li a').each(function () {
        var linkUrl = $(this).attr('href');

        if (currentUrl.includes(linkUrl)) {
            $('.textpage-nav ul li').removeClass('active');
            $(this).parent().addClass('active');
        }
    });
});

// scrollTop Y function
function scrollTo(position, speed) {

    speed = (typeof speed === "undefined") ? 500 : speed;

    $('html, body').animate({
        scrollTop: position
    }, speed);
}